import React from 'react';

export default function BlogFilters({ activeFilter, setActiveFilter }) {
  const filters = ['All', 'flockx', 'AI Agent'];

  const handleFilterChange = (value) => {
    setActiveFilter(value);
  };

  return (
    <div className='flex items-end justify-start w-full'>
      <ul className='hidden md:flex gap-2 md:gap-4 lg:gap-6'>
        {filters.map((filter, index) => (
          <li
            key={index}
            onClick={() => handleFilterChange(filter)}
            className={`cursor-pointer lg:text-lg hover:text-highlight transition ease-linear ${
              activeFilter === filter ? 'underline' : ''
            }`}
          >
            {filter}
          </li>
        ))}
      </ul>
      <label className='md:hidden ml-auto'>
        <span className='text-grey-25'>Category:</span>
        <select
          value={activeFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
          className='text-grey-25 relative border-grey-25 border-none bg-grey-25 bg-opacity-10 placeholder-grey-25 rounded-3xl mt-4 ml-2 focus:ring-highlight appearance-none'
        >
          {filters.map((filter, index) => (
            <option key={index}>{filter}</option>
          ))}
        </select>
      </label>
    </div>
  );
}
