import React from 'react';
import { graphql } from 'gatsby';

import SearchPosts from '../components/organisms/SearchPosts';
import BaseTemplate from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import SEO from '../components/atoms/utility/seo';
import Spacer from '../components/atoms/utility/Spacer';
import FeaturedPost from '../components/atoms/blog/FeaturedPost';

export default function Blog({ data, location }) {
  const posts = data.allMdx.edges;
  const heroImg = data.heroImg;
  const mobileHeroImg = data.mobileHeroImg;
  const { localSearchBlog } = data;

  return (
    <BaseTemplate
      heroContent={BlogPageHeroContent}
      heroImg={heroImg}
      mobileHeroImg={mobileHeroImg}
    >
      <SEO
        title='Stay Up-to-Date with AI Agent News and Events'
        keywords={[
          'blog',
          'stories',
          'partners',
          'network',
          'ai agent',
          'agentic',
          'multi agent',
          'personal ai agent',
          'knowledge base',
          'flockx',
          'news'
        ]}
        description={`Stay informed and ahead of the curve with the latest news and events from the flockx AI Agent App. Follow real-time updates and engage with a diverse of AI Agents to stay connected and informed.`}
      />
      <Spacer sizeY={1} />
      <SearchPosts
        posts={posts}
        localSearchBlog={localSearchBlog}
        location={location}
      />
      <Spacer sizeY={1} />
    </BaseTemplate>
  );
}

function BlogPageHeroContent() {
  return (
    <div className='-mt-[10%] sm:mt-[10%] lg:mt-[10%] text-center sm:text-left'>
      <PrimaryHeader
        headerText={`Explore AI Agents`}
        className='text-5xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[1.3] mb-2 sm:mb-4 lg:mb-4 text-white capitalize max-w-[330px] sm:max-w-sm md:max-w-xs lg:max-w-md xl:max-w-md mx-auto lg:mr-auto sm:ml-0 lg:ml-0 font-light'
      />
      <SubHeader
        headerText='Stay up to date with the latest news from flockx.'
        className='mb-4 md:mb-4 lg:mb-4 text-white mx-auto font-light max-w-[300px] sm:max-w-sm lg:max-w-lg sm:mx-0'
      />
    </div>
  );
}

// blog-hero-bg-plane
export const pageQuery = graphql`
    query {
        localSearchBlog {
            index
            store
        }
        heroImg: file(name: { eq: "blog-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        mobileHeroImg: file(name: { eq: "blog-mobile-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/blog/" } }
        ) {
            edges {
                node {
                    excerpt
                    timeToRead
                    fields {
                        slug
                    }
                    headerImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        slug
                        localHeaderImage {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        category
                    }
                }
            }
        }
    }
`;
